import '../../Style/Main.css';
import '../../Style/Projects.css';
import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../../Components/HeaderElements";

import Jonny1 from '../../Images/work/jonny/jonny-1.png';
import Jonny2 from '../../Images/work/jonny/jonny-2.png';
import Jonny3 from '../../Images/work/jonny/jonny-3.png';
import Jonny4 from '../../Images/work/jonny/jonny-4.png';
import Jonny5 from '../../Images/work/jonny/jonny-5.png';
import Jonny6 from '../../Images/work/jonny/jonny-6.png';
import Jonny7 from '../../Images/work/jonny/jonny-7.png';
import Jonny8 from '../../Images/work/jonny/jonny-8.png';
import Jonny9 from '../../Images/work/jonny/jonny-9.png';
import Jonny10 from '../../Images/work/jonny/jonny-10.png';

import ProjectFooter from "../../Components/ProjectFooter"

const Jonny = () => {
  return (
    <main id='main' className='project-page wrapper'>
      <Helmet>
        <title>Janel Lucia | Jonny Silver branding & website</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="project-atf">
        <div className="project-title">
          <p>Merging Art and Film Through Innovative Visuals</p>
          <h1>Jonny Silver</h1>
        </div>
        <div className="left">
          <p>Jonny Silver is a Toronto-based artist and filmmaker known for his exploration of psychological themes through digital photography. His distinctive style of "photomontage"—resembling intricate puzzle-images—captures the complexity of human experience and has been showcased in notable art books, web-based projects, and group exhibitions. Highlights include the Dada Reboot show at Toronto’s 2012 Nuit Blanche and the 2015 Liquid Rooms show at Palazzo Ca’Zinardi in Venice.</p>
          <p>We collaborated to develop a website that complements Jonny’s unique artistic vision. The site, built on Squarespace, is designed to reflect the depth and intricacy of his work while offering an intuitive platform for easy updates. Our goal was to create a digital space that enhances his portfolio and supports his ongoing creative endeavors, ensuring his artwork is presented with the same innovation and thoughtfulness as his artistic practice.</p>
          <NavLink to="https://www.jonny-silver.com/" target="_blank" activeStyle>Jonny Silver Website</NavLink>
        </div>
        <div className="right">
          <h2>Services</h2>
          <ul>
            <li>Typography</li>
            <li>Scruarspace Website</li>
            <li>Custom Build</li>
            <li>Web Accessibility</li>
            <li>Site Guide</li>
          </ul>
        </div>
      </section>

      <section className='three-across'>
        <img src={Jonny1} alt="Jonny Silver website visual" />
        <img src={Jonny2} alt="Jonny Silver website visual" />
        <img src={Jonny3} alt="Jonny Silver website visual" />
      </section>

      <section className='two-across'>
        <img src={Jonny4} alt="Jonny Silver website visual" />
        <img src={Jonny5} alt="Jonny Silver website visual" />
      </section>

      <section className='three-across'>
        <img src={Jonny6} alt="Jonny Silver website visual" />
        <img src={Jonny7} alt="Jonny Silver website visual" />
        <img src={Jonny8} alt="Jonny Silver website visual" />
      </section>

      <section className='two-across two-across-alt'>
        <img src={Jonny9} alt="Jonny Silver website visual" />
        <img src={Jonny10} alt="Jonny Silver website visual" />
      </section>

      <ProjectFooter />
    </main>
  );
};

export default Jonny;