import '../Style/Main.css';
import '../Style/Contact.css';
import React from "react";
import { Helmet } from 'react-helmet';



const ThankYou = () => {
  return (
    <main id='main' className="wrapper">
      <Helmet>
        <title>Janel Lucia | About</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="atf contact-atf">
        <h1>Thank you for considering working with me.</h1>
        <p>Your message has been received, and we’re thrilled to connect with you. We’ll get back to you as soon as possible, typically within 48 hours.</p>
        <p>In the meantime, feel free to explore our recent work. We’re looking forward to discussing how we can bring your vision to life.</p>
      </section>
      <br></br>
      <br></br>
    </main >
  );
};

export default ThankYou;