import React from "react";
import Accordion from "./Accordion";
import { NavLink } from "../../Components/HeaderElements";


function ShopFooter(props) {

  return (
    <>
      <div className='shop-footer'>
        <section className="how-it-works">
          <h2 className="h-italics">The Process</h2>
          {/* <p>We'll change the name, secondary logo, tagline, colours, and the option to change the fonts! (Includes one revision).</p> */}
          <p className="title-text">Within a week you'll have confidence with your new creative and cohesive brand</p>
          <div className="steps">
            <div className="step step-1">
              <h3>1</h3>
              <p>CHOOSE A BRAND KIT THAT ALIGNS WITH YOU MOST</p>
            </div>
            <div className="step step-2">
              <h3>2</h3>
              <p>SHARE YOUR BUSINESS NAME, COLOUR PALETTE & INSPO</p>
            </div>
            <div className="step step-3">
              <h3>3</h3>
              <p>RECEIVE A BRAND CUSTOMIZED JUST FOR YOU!</p>
            </div>
          </div>
        </section>
        <section className="whats-included">
          <div className="section-text">
            <h2>What’s included?</h2>
            <p>Each brand kit comes with a collection of high-end logos customized to fit your unique business. With a custom colour palette and font style guide so you don't have to guess how to style your brand. </p>
            <ul>
              <li>+ Primary Logo</li>
              <li>+ Secondary Logo(s)</li>
              <li>+ Brand Marks</li>
              <li>+ Custom Colour Palette</li>
              <li>+ Font Style Guide</li>
              <li>+ Brand Style Guide</li>
              <li>+ One Round of Revisions</li>
            </ul>
            <p>*each brand kit is only sold 5 times*</p>
          </div>
          <div className="image">
            <img src={props.image} alt="Semi Custom Brand layout" />
          </div>
        </section>
        <section className="shop-faq">
          <h2>Brand Kit FAQs</h2>
          <Accordion />
          <p className="faq-footer">If you have any questions, please don’t hesitate to reach out. We’re here to help! <br></br><NavLink to="mailto:hello@janellucia.com" target="new" activeStyle>hello@janellucia.com</NavLink></p>
        </section>
      </div>
    </>
  );
};

export default ShopFooter;