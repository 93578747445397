import '../Style/Main.css';
import '../Style/About.css';
import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../Components/HeaderElements";

import Paralax from '../Images/home/paralax-bg.jpg';

import Janel1 from '../Images/jlds/janel-lucia-7.png';
import Janel2 from '../Images/jlds/janel-lucia-4.png';
import Janel3 from '../Images/jlds/janel-lucia-12.png';
import Janel4 from '../Images/jlds/janel-lucia-8.png';
import Janel5 from '../Images/jlds/janel-lucia-2.png';
import Janel6 from '../Images/jlds/janel-lucia-3.png';

import Millie1 from '../Images/jlds/millie.png';
import Millie2 from '../Images/jlds/millie-two.png';


const About = () => {
  return (
    <main id='main' className="about-wrapper">
      <Helmet>
        <title>Janel Lucia | About</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="about-atf">
        <div className='wrapper'>
          <div className="left">
            <h2>About</h2>
            <img src={Janel1} alt="Creating a mood board" />
          </div>
          <div className='right'>
            <h1>It All Began as my <br></br>Little Creative Outlet...</h1>
            <p>On February 22nd, 2016, I took a leap. While working in the hair & beauty industry, I hit publish on my first branding and website project. The rush was exhilarating, and I quickly realized my purpose: to make the world more inspiring through design.</p>
            <p>Now, as a Designer and Front-End Developer in Toronto, I specialize in typography-driven branding, editorial design, and beautiful websites. My work is known for its thoughtfulness, elegance, and warmth, blending tactile sensibilities into engaging experiences.</p>
            <p>With experience spanning industries such as fashion, beauty, and food & beverage, I’ve had the opportunity to work with brands like Bad Girls Collective, Holt Renfrew, Hudson's Bay, and Livie Jewelry this little 'creative outlet' quickly turned from a passion to a purpose.</p>
          </div>
        </div>
      </section>

      <section className='about-intro'>
        <div className='wrapper'>
          <img src={Janel2} alt="Janel Lucia Product Design" className='image-1' />
          <div className='text'>
            <p className='sub-title'>Janel Lucia Design Studio</p>
            <h2>A Design Studio for the Passionate, Ambitious, and Authentic Brand.</h2>
            <p>I’m here to elevate your brand with strategic, heart-driven design.</p>
            <p><NavLink to="/recent-work/" activeStyle>Recent Work</NavLink></p>
          </div>
          <img src={Janel3} alt="Janel Lucia Past Projects Compilation" className='image-2' />
        </div>
      </section>

      <section className='paralax' style={{ backgroundImage: `url(${Paralax})` }}>
      </section>

      <section className='about-match'>
        <div className='wrapper'>
          <div className='left text'>
            <h3>We’re a Design Match <br className='desktop'></br>if you...</h3>
            <ol>
              <li>Value the impact of creative design.</li>
              <li>Are committed to the design process.</li>
              <li>Have a deep passion for your brand and business.</li>
              <li>Appreciate art and handcrafted details.</li>
              <li>Value integrity and are driven to grow authentically.</li>
            </ol>
          </div>
          <div className="right">
            <img src={Janel5} alt="Janel Lucia" />
          </div>
          <img src={Janel4} alt="You are made of magic" className='image-2' />
        </div>
      </section>

      <section className='about-mission'>
        <div className='wrapper'>
          <p className='sub-title'>The Mission</p>
          <h2>To Help you Create an Intentional, Strategic, and Hand-Crafted Brand & Website that will give you Confidence.</h2>
          <p>So you can attract your ideal client and take your business to the next level</p>
          <p><NavLink to="/services/" activeStyle>Services</NavLink></p>
        </div>
      </section>

      <section className="about-janel">
        <div className='wrapper'>
          <div className='mobile'>
            <p className='sub-title'>Hi, I'm Janel</p>
            <h2>Your Brand Stylist, <br></br>Art Director & Design Expert</h2>
          </div>
          <div className="left">
            <img src={Janel6} alt="Janel Lucia" />
          </div>
          <div className='right'>
            <p className='sub-title desktop'>Hi, I'm Janel</p>
            <h2 className='desktop'>Your Brand Stylist, <br></br>Art Director & Design Expert</h2>
            <p>Design isn’t just a title I picked up from education or online courses; it’s been a part of me for as long as I can remember. From teaching myself typography and branding to diving into design projects with passion, this journey has been deeply personal.</p>
            <p>I focus on typography-driven branding, editorial design, and photographic art direction. My goal is to infuse both print and digital experiences with elegance and warmth, paying attention to the details that make a difference.</p>
            <p className='desktop'>I’ve had the pleasure of working with diverse industries like fashion, beauty, food & beverage, hospitality, luxury, and publishing. Collaborating with brands such as Bad Girls Collective, Holt Renfrew, Hudson's Bay, and Livie Jewelry has been both exciting and fulfilling.</p>
          </div>
          <div className='cta'>
            <p>Getting to see clients have confidence and love for their brand will forever fuel my soul</p>
            <NavLink to="/contact" className="each-project" activeStyle>Work Together</NavLink>
          </div>
        </div>
      </section>

      <section className="about-millie">
        <div className="left">
          <img src={Millie1} alt="My beautiful tabby cat Millie enjoying flowers" />
        </div>
        <div className='right'>
          <p className='sub-title'>Meet the Cutest</p>
          <p className='millie-title'>Little Tabby Cat </p>
          <div className='millie-image-text'>
            <img src={Millie2} alt="My beautiful tabby cat Millie resting" />
            <h2>Millie</h2>
          </div>
          <p>she's gentle yet playful.<br></br>she's shy yet affectionate.<br></br>she's small yet soft.</p>
        </div>
      </section>

      <section className="client-call-to-action client-call-to-action-2">
        <div className='wrapper'>
          <h2>Ready to Take Your <br className='mobile'></br><span className="h-italics">Brand to the Next Level?</span></h2>
          <p>Let’s create something beautiful together.</p>
          <NavLink to="/contact" className="each-project" activeStyle>Get Started</NavLink>
        </div>
      </section>

    </main >
  );
};

export default About;