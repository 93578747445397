// Filename - Pages/index.js
import '../Style/Main.css';
import '../Style/Work.css';
import React from "react";
import { Helmet } from 'react-helmet';
import ImageSlider from "../Components/Shop/ImageSlider"
import 'react-slideshow-image/dist/styles.css'

import Marquee from "react-fast-marquee";
import RecentWork from "../Components/RecentWork.js";
import { NavLink } from "../Components/HeaderElements";

import Janel1 from '../Images/jlds/janel-lucia-1.png';
import Janel2 from '../Images/jlds/janel-lucia-13.png';
import Janel3 from '../Images/jlds/janel-lucia-4.png';
import Janel4 from '../Images/jlds/janel-lucia-5.png';

import Janel5 from '../Images/jlds/janel-lucia-home.png';
import Janel6 from '../Images/jlds/janel-lucia-12.png';
import Paralax from '../Images/home/paralax-bg.jpg';

const images = [Janel1, Janel2, Janel3, Janel4];


const Home = () => {
  return (
    <main className='home-page' id='main'>
      <Helmet>
        <title>Janel Lucia Design Studio</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="atf home-atf">
        <div className="wrapper">
          <h1>Beautiful & Intelligent Design for the <span className='h-italics'>Modern Brand</span></h1>
        </div>
      </section>

      <Marquee>
        <p>Logo Design / Custom Logotype</p> • <p>Branding / Visual Identity Design</p> • <p>Website Design / Build</p> • <p>Editorial Design / Books / Magazines</p> • <p>Packaging Design / Invites</p> •
      </Marquee>

      <div className='wrapper'>
        <RecentWork />
      </div>

      <section className='meet-janel'>
        <div className='wrapper'>
          <div className='left'>
            <ImageSlider images={images} />
            <img src={Janel5} alt="Stay Creative and Curious" className='home-about-footer-text' />
          </div>
          <div className='right'>
            <h2>Meet <br className='desktop'></br> Janel</h2>
            <p><strong>I'm here to spark your curiosity, inspire your creativity and help you find clarity and confidence in your brand.</strong></p>
            <p>So you can focus on your passion and create a heart-driven business you are proud of.</p>
            <p>I know what it's like to not feel 'enough' as a creative in an over-saturated industry, especially when your brand doesn't reflect who you are. I'm here to help you create a brand you love and a business that is driven to grow.</p>
            <NavLink to="/about" className="cta" activeStyle>About Janel</NavLink>
            <NavLink to="/contact" className="cta" activeStyle>Work together</NavLink>
          </div>
        </div>
      </section>

      <section className='paralax home-services' style={{ backgroundImage: `url(${Paralax})` }}>
        <div className='home-service-wrap'>
          <div className='each-service'>
            <h3>Logo / Custom Logotype</h3>
            <p>We craft logos that truly represent your brand, leaving a lasting impression. Whether you’re starting fresh or refining an existing logo, we’ll create something that resonates with your audience.</p>
          </div>
          <div className='each-service'>
            <h3>Branding / Visual Identity</h3>
            <p>We develop cohesive visual identities that capture your brand’s essence. From logos to colour palettes and typography, we ensure your brand is seen, remembered, and connected with your audience.</p>
          </div>
          <div className='each-service'>
            <h3>Website Design / Build</h3>
            <p>Your website should be as unique as your brand. We design and build sites that stand out and provide a seamless user experience, whether on Squarespace, Shopify, or WordPress.</p>
          </div>
          <div className='each-service'>
            <h3>Editorial Design </h3>
            <p>Elevate your printed products with confidence. We create PDFs, books, magazines, and packaging, complete with a custom mood board, colour palette, and creative direction.</p>
          </div>
        </div>
      </section>

      <section className='home-process' id='process'>
        <div className='left'>
          <h2>The Process</h2>
          <div className="each-step">
            <h3>01. Discovery</h3>
            <p>We start by understanding your brand, goals, and vision. This phase involves in-depth discussions and research to ensure we're aligned.</p>
          </div>
          <div className="each-step">
            <h3>02. Concept</h3>
            <p>Next, we develop initial ideas and concepts that reflect your brand's identity. You'll receive a clear vision of where we're headed.</p>
          </div>
          <div className="each-step">
            <h3>03. Design</h3>
            <p>Here, the magic happens. We bring the concepts to life, refining each detail to create a cohesive and visually compelling design.</p>
          </div>
          <div className="each-step">
            <h3>04. Delivery</h3>
            <p>Finally, we deliver the finished product, ensuring everything is polished and ready to elevate your brand.</p>
          </div>
        </div>
        <img src={Janel6} alt="You are made of magic" className='image-2' />
      </section>

      <section className='about-mission'>
        <div className='wrapper'>
          <p className='sub-title'>The Mission</p>
          <h2>To Help you Create an Intentional, Strategic, and Hand-Crafted Brand & Website that will give you Confidence.</h2>
          <p>So you can attract your ideal client and take your business to the next level</p>
          <p><NavLink to="/services/" activeStyle>Services</NavLink></p>
        </div>
      </section>

      <section className="client-call-to-action client-call-to-action-2">
        <div className='wrapper'>
          <h2>Ready to Take Your <br className='mobile'></br><span className="h-italics">Brand to the Next Level?</span></h2>
          <p>Let’s create something beautiful together.</p>
          <NavLink to="/contact" className="each-project" activeStyle>Get Started</NavLink>
        </div>
      </section>

    </main >
  );
};

export default Home;