// Filename - Pages/index.js
import '../Style/Main.css';
import '../Style/Work.css';
import React from "react";
import { Helmet } from 'react-helmet';

import { NavLink } from "../Components/HeaderElements";

import BrieHagarty from '../Images/home/brie-hagarty.png';
import BrieHagarty2 from '../Images/work/brie-hagarty/brie-hagarty-2.png';
import Scrungee from '../Images/home/the-scrungee.png';
import Scrungee2 from '../Images/home/the-scrungee-mobile.png';
import Daniela from '../Images/home/daniela-bosco.png';
import ASH from '../Images/home/anne-sophie-hout.png';

import BGC1 from '../Images/home/bad-girls-collective-1.png';
import BGC2 from '../Images/home/bad-girls-collective-2.png';
import BGC3 from '../Images/home/bad-girls-collective-3.png';
import BGC4 from '../Images/home/bad-girls-collective-4.png';

import Lorangerie from '../Images/home/lorangerie.png';
import Lorangerie2 from '../Images/home/lorangerie-mobile.png';
import Jonny from '../Images/home/jonny.png';
import Jonny2 from '../Images/work/jonny/jonny-8.png';
import CS from '../Images/home/conceived-space.png';
import JR from '../Images/home/jo-rodrigues.png';
import Livie from '../Images/home/livie-jewelry.png';
import Tulips from '../Images/home/tulipomania.png';
import Lettering from '../Images/home/lettering.png';
import Lettering2 from '../Images/work/posters/lettering-2.png';

const Work = () => {
  return (
    <main id='main' className="wrapper">
      <Helmet>
        <title>Janel Lucia Design Studio</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="atf">
        {/* <h1>Bringing Brands to Life: <br></br>Crafting Thoughtful, Impactful Designs that Elevate</h1> */}
        <h1>Timeless Design with <br></br>a <span className='h-italics'>Modern Touch</span></h1>
      </section>
      <section className='section section-1'>
        <div className='project project-landscape'>
          <div className='image'>
            <NavLink to="/recent-work/brie-hagarty">
              <h2 >High Vibe Hair Artist</h2>
              <div className='pr-image'>
                <img src={BrieHagarty} className='desktop' alt="Brie Hagarty High Vibe Hair Artist Branding visual" />
                <img src={BrieHagarty2} className="mobile" alt="Brie Hagarty High Vibe Hair Artist Branding visual" />
              </div>
              <p>— Brand Design, Logo, Squarespace Website, Brand Collateral</p>
            </NavLink>
          </div>
        </div>
      </section>

      <section className='section section-2'>
        <div className='project project-1 project-two-across'>
          <div className='image'>
            <NavLink to="/recent-work/the-scrungee" >
              <h2 >The Scrungee</h2>
              <div className='pr-image'>
                <img src={Scrungee} className='desktop' alt="The Scrungee branding visual" />
                <img src={Scrungee2} className='mobile' alt="The Scrungee branding visual" />
              </div>
              <p>— Brand Identity, Logo, Illustration, Packaging </p>
            </NavLink>
          </div>
        </div>
        <div className='project project-2 project-two-across'>
          <div className='image'>
            <NavLink to="/recent-work/daniela-bosco">
              <h2 >Daniela Bosco</h2>
              <div className='pr-image'>
                <img src={Daniela} alt="Daniela Bosco website visual" />
              </div>
              <p>— Brand Design, Logo, Squaresace Website</p>
            </NavLink>
          </div>
        </div>
      </section>

      <section className='section section-3'>
        <div className='project project-landscape'>
          <div className='image'>
            <NavLink to="/recent-work/anne-sophie-hout">
              <h2 >Anne Sophie Hout</h2>
              <div className='pr-image'>
                <img src={ASH} alt="Anne Sophie Hout Photography Website visual" />
              </div>
              <p>— Brand Design, Logo, Squarespace Website</p>
            </NavLink>
          </div>
        </div>
      </section>

      <section className='section section-4 project-four-across'>
        <div className='project'>
          <div className='image'>
            <NavLink to="/recent-work/bad-girls-collective" >
              <h2 >Bad Girls Collective</h2>
              <div className='pr-image'>
                <img src={BGC1} alt="Bad Girls Collective website visual" />
                <img src={BGC2} alt="Bad Girls Collective website visual" />
                <img src={BGC3} alt="Bad Girls Collective website visual" />
                <img src={BGC4} alt="Bad Girls Collective website visual" />
              </div>
              <p>— Shopify Website</p>
            </NavLink>
          </div>
        </div>
      </section>

      <section className='section section-5'>
        <div className='project project-landscape'>
          <div className='image'>
            <NavLink to="/recent-work/lorangerie-photographie" >
              <h2 >L'Orangerie Photographie</h2>
              <div className='pr-image'>
                <img src={Lorangerie} className='desktop' alt="L'Orangerie Photographie branding visual" />
                <img src={Lorangerie2} className='mobile' alt="L'Orangerie Photographie branding visual" />
              </div>
              <p>— Brand Design, Logo, Squarespace Website, Brand Collateral</p>
            </NavLink>
          </div>
        </div>
      </section>

      <section className='section section-6'>
        <div className='project project-1 project-two-across'>
          <div className='image'>
            <NavLink to="/recent-work/jonny-silver">
              <h2 >Jonny Silver</h2>
              <div className='pr-image'>
                <img src={Jonny} className='desktop' alt="Jonny Silver website visual" />
                <img src={Jonny2} className='mobile' alt="Jonny Silver website visual" />
              </div>
              <p>— Web Design, Custom Website, Squarespace Website</p>
            </NavLink>
          </div>
        </div>
        <div className='project project-2 project-two-across'>
          <div className='image'>
            <NavLink to="/recent-work/conceived-space">
              <h2 >Conceived Space</h2>
              <div className='pr-image'>
                <img src={CS} alt="Conceived Space editorial visual" />
              </div>
              <p>— Brand Design, Logo, Printed Assets, Wordpress Website</p>
            </NavLink>
          </div>
        </div>
      </section>

      <section className='section section-7'>
        <div className='project project-landscape'>
          <div className='image'>
            <NavLink to="/recent-work/jo-rodrigues" >
              <h2 >Jo Rodrigues</h2>
              <div className='pr-image'>
                <img src={JR} alt="Jo Rodrigues branding visual" />
              </div>
              <p>— Brand Identity, Logo, Wordpress Website, Brand Collateral</p>
            </NavLink>
          </div>
        </div>
      </section>

      <section className='section section-8'>
        <div className='project project-1 project-two-across'>
          <div className='image'>
            <NavLink to="/recent-work/tulipomania">
              <h2 >Tulipomania</h2>
              <div className='pr-image'>
                <img src={Tulips} alt="Tulip illustration visual" />
              </div>
              <p>— Illustration</p>
            </NavLink>
          </div>
        </div>
        <div className='project project-2 project-two-across'>
          <div className='image'>
            <NavLink to="/recent-work/livie-jewelry">
              <h2 >Livie Jewelry</h2>
              <div className='pr-image'>
                <img src={Livie} alt="Livie Jewelry branding visual" />
              </div>
              <p>— Brand Design, Logo, Shopify Website</p>
            </NavLink>
          </div>
        </div>
      </section>

      <section className='section section-9'>
        <div className='project project-landscape'>
          <div className='image'>
            <NavLink to="/recent-work/lettering-archive" >
              <h2 >Lettering Archive</h2>
              <div className='pr-image'>
                <img src={Lettering} className='desktop' alt="Lettering Poster visual" />
                <img src={Lettering2} className='mobile' alt="Lettering Poster visual" />
              </div>
              <p>— Graphic Design</p>
            </NavLink>
          </div>
        </div>
      </section>
      <section className="client-call-to-action">
        <div className='wrapper'>
          <h2>Ready to Take Your <span className="h-italics">Brand to the Next Level?</span></h2>
          <p>Let’s create something special together.</p>
          <NavLink to="/contact" className="each-project" activeStyle>Let's Make Magic</NavLink>
        </div>
      </section>
    </main >
  );
};

export default Work;