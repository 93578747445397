import '../Style/Main.css';
import '../Style/Footer.css';
import { NavLink } from "./HeaderElements";




function Footer() {
  return (
    <>
      <footer id='footer' className='Footer'>
        <div className='wrapper f-wrapper'>
          <div className='nav-links desktop'>
            <NavLink to="/about">About</NavLink>
            <NavLink to="/recent-work">Work</NavLink>
            <NavLink to="/services">Services</NavLink>
          </div>
          <div className='footer-center'>
            <NavLink to="/" className="logo" activeStyle>janel lucia // design studio</NavLink>
            <div className='socials'>
              <NavLink to="https://www.instagram.com/janellucia_design/" target="_blank" className="each-social" activeStyle>Insta</NavLink>
              <NavLink to="https://www.pinterest.ca/janellucia_design" target="_blank" className="each-social" activeStyle>Pinterest</NavLink>
              <NavLink to="https://www.linkedin.com/in/janel-lucia/" target="_blank" className="each-social" activeStyle>LinkedIn</NavLink>
            </div>
            <div className='nav-links mobile'>
              <NavLink to="/recent-work">Work</NavLink>
              <NavLink to="/about">About</NavLink>
              <NavLink to="/services">Services</NavLink>
              <NavLink to="/shop">Shop</NavLink>
              <NavLink to="/#process">Process</NavLink>
              <NavLink to="/contact">Contact</NavLink>
            </div>
          </div>
          <div className='nav-links desktop'>
            <NavLink to="/shop">Shop</NavLink>
            <NavLink to="/#process">Process</NavLink>
            <NavLink to="/contact">Contact</NavLink>
          </div>
          <p className='copyright'>© 2016 – 2024 Janel Lucia Design Studio. All Rights Reserved. <br className='mobile'></br>Brand Design and Site Creation by Janel Lucia.</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;