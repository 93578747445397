import '../../Style/Main.css';
import '../../Style/Projects.css';
import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../../Components/HeaderElements";

import Lorangerie1 from '../../Images/work/lorangerie/lorangerie-1.png';
import Lorangerie2 from '../../Images/work/lorangerie/lorangerie-2.png';
import Lorangerie3 from '../../Images/work/lorangerie/lorangerie-3.png';
import Lorangerie4 from '../../Images/work/lorangerie/lorangerie-4.png';
import Lorangerie5 from '../../Images/work/lorangerie/lorangerie-5.png';
import Lorangerie6 from '../../Images/work/lorangerie/lorangerie-6.png';
import Lorangerie7 from '../../Images/work/lorangerie/lorangerie-7.png';
import Lorangerie8 from '../../Images/work/lorangerie/lorangerie-8.png';
import Lorangerie9 from '../../Images/work/lorangerie/lorangerie-9.png';
import Lorangerie10 from '../../Images/work/lorangerie/lorangerie-10.png';
import Lorangerie11 from '../../Images/work/lorangerie/lorangerie-11.png';

import ProjectFooter from "../../Components/ProjectFooter"

const Lorangerie = () => {
  return (
    <main id='main' className='project-page wrapper'>
      <Helmet>
        <title>Janel Lucia | L'Orangerie Photographie branding & website</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="project-atf">
        <div className="project-title">
          <p>Capturing Intimate Moments with Elegance</p>
          <h1>L'Orangerie Photographie</h1>
        </div>
        <div className="left">
          <p>Sadie at L'Orangerie Photographie specializes in wedding and family photography, capturing intimate and creative moments both in the city and the countryside. Her work focuses on creating heartfelt images that reflect the unique beauty and romance of each occasion.</p>
          <p>We collaborated with Sadie to develop a brand identity that complements her artistic vision. This included designing a distinctive logo with unique typography, creating social media layouts, and building a custom Squarespace website. The site features tailored coding to enhance user experience, ensuring that visitors feel the same warmth and ease that Sadie brings to her photography. The result is a digital presence that beautifully showcases her intimate and romantic style, inviting users to connect with her work.</p>
          <NavLink to="https://www.orangeriephotographie.com/" target="_blank" activeStyle>L'Orangerie Website</NavLink>
        </div>
        <div className="right">
          <h2>Services</h2>
          <ul>
            <li>Brand Identity</li>
            <li>Logo Design</li>
            <li>Squarespace site</li>
            <li>Web Accessibility</li>
            <li>Social Media</li>
          </ul>
        </div>
      </section>

      <section className='three-across'>
        <img src={Lorangerie1} alt="L'Orangerie Photographie website and Branding visual" />
        <img src={Lorangerie2} alt="L'Orangerie Photographie website and Branding visual" />
        <img src={Lorangerie3} alt="L'Orangerie Photographie website and Branding visual" />
      </section>

      <section className='two-across'>
        <img src={Lorangerie4} alt="L'Orangerie Photographie website and Branding visual" />
        <img src={Lorangerie5} alt="L'Orangerie Photographie website and Branding visual" />
      </section>

      <section className='three-across'>
        <img src={Lorangerie6} alt="L'Orangerie Photographie website and Branding visual" />
        <img src={Lorangerie7} alt="L'Orangerie Photographie website and Branding visual" />
        <img src={Lorangerie8} alt="L'Orangerie Photographie website and Branding visual" />
      </section>

      <section className='one-across'>
        <img src={Lorangerie9} alt="L'Orangerie Photographie website and Branding visual" />
      </section>

      <section className='two-across two-across-alt'>
        <img src={Lorangerie10} alt="L'Orangerie Photographie website and Branding visual" />
        <img src={Lorangerie11} alt="L'Orangerie Photographie website and Branding visual" />
      </section>

      <ProjectFooter />
    </main>
  );
};

export default Lorangerie;