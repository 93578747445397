import '../../Style/Main.css';
import '../../Style/Projects.css';
import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../../Components/HeaderElements";

import Jo1 from '../../Images/work/jo-rodrigues/jo-1.png';
import Jo2 from '../../Images/work/jo-rodrigues/jo-2.png';
import Jo3 from '../../Images/work/jo-rodrigues/jo-3.png';
import Jo4 from '../../Images/work/jo-rodrigues/jo-4.png';
import Jo5 from '../../Images/work/jo-rodrigues/jo-5.png';
import Jo6 from '../../Images/work/jo-rodrigues/jo-6.png';
import Jo7 from '../../Images/work/jo-rodrigues/jo-7.png';
import Jo8 from '../../Images/work/jo-rodrigues/jo-8.png';
import Jo9 from '../../Images/work/jo-rodrigues/jo-9.png';
import Jo10 from '../../Images/work/jo-rodrigues/jo-10.png';


import ProjectFooter from "../../Components/ProjectFooter"

const JoRodrigues = () => {
  return (
    <main id='main' className='project-page wrapper'>
      <Helmet>
        <title>Janel Lucia | Jo Rodrigues branding & website</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="project-atf">
        <div className="project-title">
          <p>Guiding Humanitarian Professionals with Integrity</p>
          <h1>Jo Rodrigues</h1>
        </div>
        <div className="left">
          <p>Jo Rodrigues specializes in coaching humanitarian aid and international development workers, offering guidance to help her clients navigate their careers with clarity and without compromising their values. Her work is dedicated to empowering professionals to achieve their career goals while staying true to their core principles.</p>
          <p>We collaborated to develop a brand identity that reflects Jo’s commitment to integrity and clarity. This included designing a distinctive logo and creating a brand identity with strong typography. Additionally, we built a custom WordPress website featuring accessible design and a Dark Mode capability to enhance user experience. The result is a polished, functional digital presence that supports Jo’s mission and allows her to connect effectively with her clients.</p>
          <NavLink to="https://jorodrigues.net/" target="_blank" activeStyle>Jo Rodrigues Website</NavLink>
        </div>
        <div className="right">
          <h2>Services</h2>
          <ul>
            <li>Brand Identity</li>
            <li>Logo Design</li>
            <li>Wordpress Site</li>
            <li>Editorial</li>
            <li>Social Media</li>
          </ul>
        </div>
      </section>

      <section className='three-across'>
        <img src={Jo1} alt="Jo Rodrigues website and Branding visual" />
        <img src={Jo2} alt="Jo Rodrigues website and Branding visual" />
        <img src={Jo3} alt="Jo Rodrigues website and Branding visual" />
      </section>

      <section className='two-across'>
        <img src={Jo4} alt="Jo Rodrigues website and Branding visual" />
        <img src={Jo5} alt="Jo Rodrigues website and Branding visual" />
      </section>

      <section className='three-across'>
        <img src={Jo6} alt="Jo Rodrigues website and Branding visual" />
        <img src={Jo7} alt="Jo Rodrigues website and Branding visual" />
        <img src={Jo8} alt="Jo Rodrigues website and Branding visual" />
      </section>

      <section className='two-across two-across-alt'>
        <img src={Jo9} alt="Jo Rodrigues website and Branding visual" />
        <img src={Jo10} alt="Jo Rodrigues website and Branding visual" />
      </section>

      <ProjectFooter />
    </main>
  );
};

export default JoRodrigues;