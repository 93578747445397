import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
`;

export const NavLink = styled(Link)`
`;


export const NavMenu = styled.div`
`;