import '../../Style/Main.css';
import '../../Style/Projects.css';
import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../../Components/HeaderElements";

import BadGirls1 from '../../Images/work/bad-girls/bad-girls-collective-1.png';
import BadGirls2 from '../../Images/work/bad-girls/bad-girls-collective-2.png';
import BadGirls3 from '../../Images/work/bad-girls/bad-girls-collective-3.png';
import BadGirls4 from '../../Images/work/bad-girls/bad-girls-collective-4.png';
import BadGirls5 from '../../Images/work/bad-girls/bad-girls-collective-5.png';
import BadGirls6 from '../../Images/work/bad-girls/bad-girls-collective-6.png';
import BadGirls7 from '../../Images/work/bad-girls/bad-girls-collective-7.png';
import BadGirls8 from '../../Images/work/bad-girls/bad-girls-collective-8.png';
import BadGirls9 from '../../Images/work/bad-girls/bad-girls-collective-9.png';
import BadGirls10 from '../../Images/work/bad-girls/bad-girls-collective-10.png';

import ProjectFooter from "../../Components/ProjectFooter"

const BadGirls = () => {
  return (
    <main id='main' className='project-page wrapper'>
      <Helmet>
        <title>Janel Lucia | Bad Girls Collective Shopify website</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="project-atf">
        <div className="project-title">
          <p>Empowering Women Through Connection & Culture</p>
          <h1>Bad Girls Collective</h1>
        </div>
        <div className="left">
          <p>Founded in 2014, Bad Girls Collective is a vibrant community of bold, intellectually-driven women with a shared passion for literature, creativity, and culture. Originally launched as a mega book club, it has evolved into a dynamic network where women can connect, learn, and inspire one another through meaningful conversations and shared experiences. The collective celebrates the power of women coming together to challenge norms and spark new ideas.</p>
          <p>We collaborated with Bad Girls Collective to bring their existing branding and web designs to life on Shopify. Our focus was on creating a digital platform that reflects their bold energy while allowing them to easily manage and update content as the collective grows. The site is built with custom sections and an intuitive interface, ensuring flexibility and accessibility. This project highlights our commitment to crafting designs that not only look great but also empower our clients to thrive independently. Explore how we helped the Bad Girls Collective amplify their voice through thoughtful, strategic design.</p>
          <NavLink to="https://badgirlscollective.ca/" target="_blank" activeStyle>Bad Girls Collective Website</NavLink>
        </div>
        <div className="right">
          <h2>Services</h2>
          <ul>
            <li>Shopify Website</li>
            <li>Custom Build</li>
            <li>Website Support</li>
            <li>Web Accessibility</li>
            <li>Site Guide</li>
          </ul>
        </div>
      </section>

      <section className='three-across'>
        <img src={BadGirls1} alt="Bad Girls Collective website visual" />
        <img src={BadGirls2} alt="Bad Girls Collective website visual" />
        <img src={BadGirls3} alt="Bad Girls Collective website visual" />
      </section>

      <section className='two-across'>
        <img src={BadGirls4} alt="Bad Girls Collective website visual" />
        <img src={BadGirls5} alt="Bad Girls Collective website visual" />
      </section>

      <section className='three-across'>
        <img src={BadGirls6} alt="Bad Girls Collective website visual" />
        <img src={BadGirls7} alt="Bad Girls Collective website visual" />
        <img src={BadGirls8} alt="Bad Girls Collective website visual" />
      </section>

      <section className='two-across two-across-alt'>
        <img src={BadGirls9} alt="Bad Girls Collective website visual" />
        <img src={BadGirls10} alt="Bad Girls Collective website visual" />
      </section>

      <ProjectFooter />
    </main>
  );
};

export default BadGirls;