import React from "react";
import '../Style/Header.css';
import { NavLink } from "./HeaderElements";



function ProjectFooter() {

  return (
    <>
      <section className='project-footer'>
        <h2>other Projects —</h2>
        <div className="project-links">
          <h3><NavLink to="/recent-work/brie-hagarty" activeStyle>Brie Hagarty / </NavLink></h3>
          <h3><NavLink to="/recent-work/the-scrungee" activeStyle> The Scrungee / </NavLink></h3>
          <h3><NavLink to="/recent-work/daniela-bosco" activeStyle> Daniela Bosco / </NavLink></h3>
          <h3><NavLink to="/recent-work/anne-sophie-hout" activeStyle> Anne Sophie Hout / </NavLink></h3>
          <h3><NavLink to="/recent-work/bad-girls-collective" activeStyle> Bad Girls Collective / </NavLink></h3>
          <h3><NavLink to="/recent-work/lorangerie-photographie" activeStyle> L'Orangerie Photographie / </NavLink></h3>
          <h3><NavLink to="/recent-work/jonny-silver" activeStyle> Jonny Silver / </NavLink></h3>
          <h3><NavLink to="/recent-work/conceived-space" activeStyle> Conceived Space / </NavLink></h3>
          <h3><NavLink to="/recent-work/jo-rodrigues" activeStyle> Jo Rodrigues / </NavLink></h3>
          <h3><NavLink to="/recent-work/livie-jewelry" activeStyle> Livie Jewelry / </NavLink></h3>
          <h3><NavLink to="/recent-work/tulipomania" activeStyle> Tulipomania / </NavLink></h3>
          <h3><NavLink to="/recent-work/lettering-archive" activeStyle> Lettering Archive</NavLink></h3>
        </div>
      </section>
    </>
  );
};

export default ProjectFooter;