import '../../Style/Main.css';
import '../../Style/Projects.css';
import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../../Components/HeaderElements";

import Conceived1 from '../../Images/work/conceived/conceived-1.png';
import Conceived2 from '../../Images/work/conceived/conceived-2.png';
import Conceived3 from '../../Images/work/conceived/conceived-3.png';
import Conceived4 from '../../Images/work/conceived/conceived-4.png';
import Conceived5 from '../../Images/work/conceived/conceived-5.png';
import Conceived6 from '../../Images/work/conceived/conceived-6.png';
import Conceived7 from '../../Images/work/conceived/conceived-7.png';
import Conceived8 from '../../Images/work/conceived/conceived-8.png';
import Conceived9 from '../../Images/work/conceived/conceived-9.png';
import Conceived10 from '../../Images/work/conceived/conceived-10.png';
import Conceived11 from '../../Images/work/conceived/conceived-11.png';

import ProjectFooter from "../../Components/ProjectFooter"

const Conceived = () => {
  return (
    <main id='main' className='project-page wrapper'>
      <Helmet>
        <title>Janel Lucia | Conceived Space branding, website & editorial</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="project-atf">
        <div className="project-title">
          <p>Redefining Art, Culture, and Identity</p>
          <h1>Conceived Space</h1>
        </div>
        <div className="left">
          <p>Conceived Space is a non-issued publication dedicated to pushing the boundaries of art, culture, design, and identity. With a mission to explore new perspectives and challenge conventional narratives, Conceived Space creates a platform where creativity and critical thought intersect, inviting readers to engage with art and culture in a fresh, impactful ways.</p>
          <p>We partnered with Conceived Space to develop a brand identity that mirrors their inclusive approach. The project encompassed the design of a minimalist website, a cohesive social media strategy, and the creation of a visually distinct print magazine. Each edit features clear visual shifts, maintaining a clean, minimal aesthetic while allowing each issue to stand out on its own. Our main goal was to balance consistency and innovation, crafting a brand experience that evolves while staying true to its core identity.</p>
          <NavLink to="https://indd.adobe.com/view/c36ce075-38fe-4c43-b400-f15ec0cf0f1e" target="_blank" activeStyle>The Visual Edit</NavLink>

        </div>
        <div className="right">
          <h2>Services</h2>
          <ul>
            <li>Brand Identity</li>
            <li>Logo Design</li>
            <li>Wordpress Site</li>
            <li>Editorial</li>
            <li>Social Media</li>
          </ul>
        </div>
      </section>

      <section className='three-across'>
        <img src={Conceived1} alt="Conceived Space Branding and Magazine visual" />
        <img src={Conceived2} alt="Conceived Space Branding and Magazine visual" />
        <img src={Conceived3} alt="Conceived Space Branding and Magazine visual" />
      </section>

      <section className='two-across'>
        <img src={Conceived4} alt="Conceived Space Branding and Magazine visual" />
        <img src={Conceived5} alt="Conceived Space Branding and Magazine visual" />
      </section>

      <section className='three-across'>
        <img src={Conceived6} alt="Conceived Space Branding and Magazine visual" />
        <img src={Conceived7} alt="Conceived Space Branding and Magazine visual" />
        <img src={Conceived8} alt="Conceived Space Branding and Magazine visual" />
      </section>

      <section className='one-across'>
        <img src={Conceived11} alt="Conceived Space Branding and Magazine visual" />
      </section>

      <section className='two-across two-across-alt'>
        <img src={Conceived9} alt="Conceived Space Branding and Magazine visual" />
        <img src={Conceived10} alt="Conceived Space Branding and Magazine visual" />
      </section>

      <ProjectFooter />
    </main>
  );
};

export default Conceived;