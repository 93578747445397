import '../../Style/Main.css';
import '../../Style/Projects.css';
import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../../Components/HeaderElements";

import Tulips1 from '../../Images/work/tulips/tulips-1.png';
import Tulips2 from '../../Images/work/tulips/tulips-2.png';
import Tulips3 from '../../Images/work/tulips/tulips-3.png';
import Tulips4 from '../../Images/work/tulips/tulips-4.png';
import Tulips5 from '../../Images/work/tulips/tulips-5.png';
import Tulips6 from '../../Images/work/tulips/tulips-6.png';


import ProjectFooter from "../../Components/ProjectFooter"

const Tulips = () => {
  return (
    <main id='main' className='project-page wrapper'>
      <Helmet>
        <title>Janel Lucia | Having Fun</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="project-atf">
        <div className="project-title">
          <p>Exploring the Art and Beauty of Tulips</p>
          <h1>Tulipomania</h1>
        </div>
        <div className="left">
          <p>Tulipomania is a personal project dedicated to the art of drawing tulips, inspired by the iconic works of painters and botanists such as Pierre-Joseph Redouté, Marianne North, and Daniel De Heem. This project delves into the delicate beauty of tulips, capturing their intricate details and vibrant forms through a series of detailed drawings.</p>
          <p>Drawing flowers has always been a calming and meditative practice for me. Through Tulipomania, I explore how the subtle intricacies of each petal and leaf convey a sense of the mystery contained within a single flower. This project is a reflection of my passion for botanical art and the serene joy I find in illustrating these natural beauties.</p>
          <p>Discover how the art of tulip drawing can reveal the profound beauty and complexity of nature.</p>
          <NavLink to="https://society6.com/senseaddictions/designs" target="_blank" activeStyle>Shop Posters</NavLink>
        </div>
      </section>

      <section className='three-across'>
        <img src={Tulips1} alt="Tulip Illustration visual" />
        <img src={Tulips2} alt="Tulip Illustration visual" />
        <img src={Tulips3} alt="Tulip Illustration visual" />
      </section>

      <section className='three-across'>
        <img src={Tulips4} alt="Tulip Illustration visual" />
        <img src={Tulips5} alt="Tulip Illustration visual" />
        <img src={Tulips6} alt="Tulip Illustration visual" />
      </section>

      <ProjectFooter />
    </main >
  );
};

export default Tulips;