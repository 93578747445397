import '../../Style/Main.css';
import '../../Style/Shop-Page.css';
import 'react-slideshow-image/dist/styles.css'
import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../../Components/HeaderElements";
import Reco from "../../Components/Shop/Reco";

import Romantic1 from '../../Images/shop/classic-romantic/classic-romantic-1.png';
import Romantic2 from '../../Images/shop/classic-romantic/classic-romantic-2.png';
import Romantic3 from '../../Images/shop/classic-romantic/classic-romantic-3.png';
import Romantic4 from '../../Images/shop/classic-romantic/classic-romantic-4.png';
import Romantic5 from '../../Images/shop/classic-romantic/classic-romantic-5.png';
import Romantic6 from '../../Images/shop/classic-romantic/classic-romantic-6.png';
import Romantic7 from '../../Images/shop/classic-romantic/classic-romantic-7.png';
import Romantic8 from '../../Images/shop/classic-romantic/classic-romantic-8.png';
import Romantic9 from '../../Images/shop/classic-romantic/classic-romantic-9.png';
import Romantic10 from '../../Images/shop/classic-romantic/classic-romantic-10.png';
import Romantic11 from '../../Images/shop/classic-romantic/classic-romantic-11.png';
import Romantic12 from '../../Images/shop/classic-romantic/classic-romantic-12.png';

import CRspread from '../../Images/shop/classic-romantic/cr-spread.png';
import CRfooter from '../../Images/shop/classic-romantic/cr-footer.png';

import CR1 from '../../Images/shop/classic-romantic/cr-1.png';
import CR2 from '../../Images/shop/classic-romantic/cr-2.png';
import CR3 from '../../Images/shop/classic-romantic/cr-3.png';
import CR4 from '../../Images/shop/classic-romantic/cr-4.png';
import CR5 from '../../Images/shop/classic-romantic/cr-5.png';
import CR6 from '../../Images/shop/classic-romantic/cr-6.png';

import ShopPageIntro from "../../Components/Shop/ShopPageIntro"
import ShopFooter from "../../Components/Shop/ShopFooter"
import ImageSlider from "../../Components/Shop/ImageSlider"

// reco images
import Minimal1 from '../../Images/shop/minimal-sleek/minimal-sleek-1.png';
import Minimal2 from '../../Images/shop/minimal-sleek/minimal-sleek-2.png';
import Clean1 from '../../Images/shop/clean-classic/clean-classic-1.png';
import Clean2 from '../../Images/shop/clean-classic/clean-classic-2.png';
import Timeless1 from '../../Images/shop/classic-timeless/classic-timeless-1.png';
import Timeless2 from '../../Images/shop/classic-timeless/classic-timeless-2.png';

const images = [CR1, CR2, CR3, CR4, CR5, CR6];

const Romantic = () => {
  return (
    <main id='main' className='shop-page shop-single'>
      <Helmet>
        <title>Janel Lucia | Clean & Classic Semi-Custom Brand</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="shop-atf">
        <div className="left">
          <ImageSlider images={images} />
        </div>
        <div className="right">
          <p>A Classic & Romantic Semi-Custom Brand:</p>
          <h1>Lock & Luxe</h1>
          <p>Introducing our Classic & Romantic Semi-Custom Brand Kit—a timeless elegance with a touch of romance. This semi-custom brand captures the essence of a classic yet sophisticated creative studio, designed to make every client feel luxurious and cherished. With its refined aesthetic and soft, romantic details, Lock & Luxe offers a brand experience that resonates with those who appreciate beauty, warmth, and grace.</p>
          <NavLink to="https://www.paypal.com/ncp/payment/HAGQW5EMN2XGG" target="new" activeStyle>Get this Brand customized for you</NavLink>
        </div>
      </section>
      <ShopPageIntro bgimage={CRspread} />

      <section className='wrapper shop-images'>
        <NavLink to="https://www.paypal.com/ncp/payment/HAGQW5EMN2XGG" target="new" className="floating-button" activeStyle>Buy Now</NavLink>
        <div className='section-text'>
          <h2>What is a Semi-Custom brand?</h2>
          <p>With 10 years of experience, I understand what it takes to create a brand that not only looks great but also connects and attracts. A semi-custom brand is ideal for entrepreneurs who want to invest in something that aligns with their business goals and values, without the need to spend over $5K or wait 6+ weeks to bring it to life.</p>
          <p>The semi-custom option transforms a pre-designed brand "template" by customizing it with your name, colours, fonts, and inspiration, along with my unique lettering. It offers a personalized experience with a faster turnaround and a more budget-friendly price. Rest assured, I ensure each brand stands out and looks entirely unique!</p>
        </div>
        <div className='section-text-2'><p>We'll change the name, secondary logo, tagline, colours, and the option to change the fonts! (includes one revision)</p></div>
        <img src={Romantic1} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Romantic2} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Romantic3} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Romantic4} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Romantic6} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Romantic5} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Romantic8} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Romantic7} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Romantic10} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Romantic9} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Romantic11} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Romantic12} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
      </section>
      <ShopFooter image={CRfooter} />
      <section className="client-call-to-action">
        <h2>Ready to Take Your <br className='mobile'></br><span className="h-italics">Brand to the Next Level?</span></h2>
        <p>Get this Brand customized for you.</p>
        <NavLink to="https://www.paypal.com/ncp/payment/HAGQW5EMN2XGG" target="new" className="call-to-action-btn" activeStyle>Buy Now</NavLink>
      </section>
      <Reco
        titleOne="Clean & Classic"
        linkOne="/shop/clean-and-classic"
        imageOne={Clean1}
        imageTwo={Clean2}
        titleTwo="Minimal & Sleek"
        linkTwo="/shop/minimal-and-sleek"
        imageThree={Minimal1}
        imageFour={Minimal2}
        titleThree="Classic & Timeless"
        linkThree="/shop/classic-and-timeless"
        imageFive={Timeless1}
        imageSix={Timeless2} />
    </main>
  );
};

export default Romantic;