import '../Style/Footer.css';
import { NavLink } from "./HeaderElements";


function Accessibility() {
  return (
    <>
      <div>
        <NavLink to="#main" className="skip-main">Skip to Main</NavLink>
        <NavLink to="#footer" className="skip-main">Skip to Footer</NavLink>
      </div>
    </>
  );
};

export default Accessibility;