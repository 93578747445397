import React from "react";
import '../Style/Header.css';
import { NavLink } from "./HeaderElements";

import Clean1 from '../Images/work/brie-hagarty/brie-hagarty-2.png';
import Clean2 from '../Images/home/anne-sophie-hout.png';
import Clean3 from '../Images/home/conceived-space.png';
import Clean4 from '../Images/home/bad-girls-collective-4.png'

function RecentWork() {

  return (
    <>
      <section className="recent-work">
        <h2>Recent Work</h2>
        <div className="projects">
          <NavLink to="/recent-work/brie-hagarty" className="each-project" activeStyle>
            <img src={Clean1} alt="" />
          </NavLink>
          <NavLink to="/recent-work/anne-sophie-hout" className="each-project" activeStyle>
            <img src={Clean2} alt="" />
          </NavLink>
          <NavLink to="/recent-work/conceived-space" className="each-project" activeStyle>
            <img src={Clean3} alt="" />
          </NavLink>
          <NavLink to="/recent-work/bad-girls-collective" className="each-project" activeStyle>
            <img src={Clean4} alt="" />
          </NavLink>
        </div>
        <NavLink to="/recent-work" className="see-all" activeStyle>See all Recent Work</NavLink>
      </section>
    </>
  );
};

export default RecentWork;