import React from "react";
import './Style/Main.css';

import {
  BrowserRouter as Router, Route, Routes,
} from 'react-router-dom';

import Accessibility from "./Components/Accessibility";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import ScrollToTop from "./Components/ScrollToTop";

import Home from "./Pages";
import Work from "./Pages/Work";
import Services from "./Pages/Services";
import Shop from "./Pages/Shop";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import ThankYou from "./Pages/ThankYou";

import Brie from "./Pages/Work/BrieHagarty";
import Scrungee from "./Pages/Work/Scrungee";
import Daniela from "./Pages/Work/Daniela";
import AnneSophie from "./Pages/Work/AnneSophie";
import BadGirls from "./Pages/Work/BadGirls";
import Lorangerie from "./Pages/Work/Lorangerie";
import Jonny from "./Pages/Work/Jonny";
import Conceived from "./Pages/Work/Conceived";
import JoRodrigues from "./Pages/Work/JoRodrigues";
import Livie from "./Pages/Work/Livie";
import Tulips from "./Pages/Work/Tulips";
import Lettering from "./Pages/Work/Lettering";

import Clean from "./Pages/Shop/Clean";
import Minimal from "./Pages/Shop/Minimal";
import Romantic from "./Pages/Shop/Romantic";
import Timeless from "./Pages/Shop/Timeless";


const NotFound = () => {
  return (
    <>
      <main className="wrapper error-page">
        <section className="error-wrap">
          <h1>404 - Page Not Found</h1>
          <p>Sorry, the page you are looking for could not be found.</p>
        </section>
      </main>
    </>
  );
};

function App() {
  return (
    <Router>
      <Accessibility />
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/#process" element={<Home />} />
        <Route exact path="/recent-work" element={<Work />} />
        <Route path="/services" element={<Services />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/recent-work/brie-hagarty" element={<Brie />} />
        <Route path="/recent-work/the-scrungee" element={<Scrungee />} />
        <Route path="/recent-work/daniela-bosco" element={<Daniela />} />
        <Route path="/recent-work/anne-sophie-hout" element={<AnneSophie />} />
        <Route path="/recent-work/bad-girls-collective" element={<BadGirls />} />
        <Route path="/recent-work/lorangerie-photographie" element={<Lorangerie />} />
        <Route path="/recent-work/jonny-silver" element={<Jonny />} />
        <Route path="/recent-work/conceived-space" element={<Conceived />} />
        <Route path="/recent-work/jo-rodrigues" element={<JoRodrigues />} />
        <Route path="/recent-work/livie-jewelry" element={<Livie />} />
        <Route path="/recent-work/tulipomania" element={<Tulips />} />
        <Route path="/recent-work/lettering-archive" element={<Lettering />} />
        <Route path="/shop/clean-and-classic" element={<Clean />} />
        <Route path="/shop/minimal-and-sleek" element={<Minimal />} />
        <Route path="/shop/classic-and-romantic" element={<Romantic />} />
        <Route path="/shop/classic-and-timeless" element={<Timeless />} />
        {/* This is where magic happens */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <ScrollToTop />
    </Router>
  );
}


export default App;