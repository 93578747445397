import '../../Style/Main.css';
import '../../Style/Projects.css';
import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../../Components/HeaderElements";

import Daniela1 from '../../Images/work/daniela/daniela-1.png';
import Daniela2 from '../../Images/work/daniela/daniela-2.png';
import Daniela3 from '../../Images/work/daniela/daniela-3.png';
import Daniela4 from '../../Images/work/daniela/daniela-4.png';
import Daniela5 from '../../Images/work/daniela/daniela-5.png';
import Daniela6 from '../../Images/work/daniela/daniela-6.png';
import Daniela7 from '../../Images/work/daniela/daniela-7.png';
import Daniela8 from '../../Images/work/daniela/daniela-8.png';
import Daniela9 from '../../Images/work/daniela/daniela-9.png';
import Daniela10 from '../../Images/work/daniela/daniela-10.png';
import Daniela11 from '../../Images/work/daniela/daniela-11.png';

import ProjectFooter from "../../Components/ProjectFooter"

const Daniela = () => {
  return (
    <main id='main' className='project-page wrapper'>
      <Helmet>
        <title>Janel Lucia | Daniela Bosco branding & website</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="project-atf">
        <div className="project-title">
          <p>Crafting a Cohesive Vision with Creative Elegance</p>
          <h1>Daniela Bosco</h1>
        </div>
        <div className="left">
          <p>Daniela Bosco specializes in creative direction, commercial, production, and styling. Working clients including Majid Jordan, Lamar Johnson, and Saya Gray her expertise lies in blending artistic vision with commercial precision, creating visual narratives that are both captivating and refined. Daniela’s work exemplifies sophistication and clarity, making a lasting impact across various projects.</p>
          <p>We collaborated to develop a brand identity that reflects Daniela’s distinctive style. This included designing a unique logo and creating a custom minimalist website on Squarespace. The goal was to capture her clean, elegant vision while ensuring a cohesive look and feel across all digital and branding materials. The result is a brand presence that seamlessly integrates with Daniela’s creative direction, enhancing her ability to showcase her work in a polished and impactful way.</p>
          <NavLink to="https://www.danielabosco.com/" target="_blank" activeStyle>aniela Bosco Website</NavLink>
        </div>
        <div className="right">
          <h2>Services</h2>
          <ul>
            <li>Brand Identity</li>
            <li>Logo Design</li>
            <li>Squarespace site</li>
            <li>Custom Website</li>
            <li>Web Accessibility</li>
          </ul>
        </div>
      </section>

      <section className='three-across'>
        <img src={Daniela1} alt="Daniela Bosco website visual" />
        <img src={Daniela2} alt="Daniela Bosco website visual" />
        <img src={Daniela3} alt="Daniela Bosco website visual" />
      </section>

      <section className='two-across'>
        <img src={Daniela4} alt="Daniela Bosco website visual" />
        <img src={Daniela5} alt="Daniela Bosco website visual" />
      </section>

      <section className='three-across'>
        <img src={Daniela6} alt="Daniela Bosco website visual" />
        <img src={Daniela7} alt="Daniela Bosco website visual" />
        <img src={Daniela8} alt="Daniela Bosco website visual" />
      </section>

      <section className='one-across'>
        <img src={Daniela9} alt="Daniela Bosco website visual" />
      </section>

      <section className='two-across two-across-alt'>
        <img src={Daniela10} alt="Daniela Bosco website visual" />
        <img src={Daniela11} alt="Daniela Bosco website visual" />
      </section>

      <ProjectFooter />
    </main>
  );
};

export default Daniela;