import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../Components/HeaderElements";
import '../Style/Main.css';
import '../Style/Home.css';
import '../Style/Shop.css';
import ShopFooter from "../Components/Shop/ShopFooter";
import FooterImage from '../Images/jlds/shop-home-footer.png';

import Clean1 from '../Images/shop/clean-classic/clean-classic-1.png';
import Clean2 from '../Images/shop/clean-classic/clean-classic-2.png';

import Minimal1 from '../Images/shop/minimal-sleek/minimal-sleek-1.png';
import Minimal2 from '../Images/shop/minimal-sleek/minimal-sleek-2.png';

import Romantic1 from '../Images/shop/classic-romantic/classic-romantic-6.png';
import Romantic2 from '../Images/shop/classic-romantic/classic-romantic-2.png';

import Timeless1 from '../Images/shop/classic-timeless/classic-timeless-1.png';
import Timeless2 from '../Images/shop/classic-timeless/classic-timeless-2.png';


const Shop = () => {
  const [open, setOpen] = useState('');
  const unhover = () => {
    setOpen('')
  }
  return (
    <main id='main' className="wrapper shop-page">
      <Helmet>
        <title>Janel Lucia | Shop</title>
        <meta name="description" content="Semi-Custum Brand Kits designed to Help you Grow your Brand with Style & Confidence." />
      </Helmet>
      <section className="atf shop-aft">
        {/* <p>Semi-Custom Brand Kits</p> */}
        <h1>Creative Templates Designed to Help you Grow your Brand with <span className="h-italics">Style & Confidence.</span></h1>
        <p>(at an affordable cost)</p>
      </section>
      <section className="intro">
        <h2><span>Introducing Semi-Custom Brand Kits.</span> <br></br>Elevated and creative brands that are customized just for you by an experienced designer.</h2>
        <p>These limited-edition brand kits will give you a cohesive, <br></br>elevated brand look & feel for half the time and half the cost.</p>
        <div className="brand-kit-wrapper">
          <NavLink to="/shop/clean-and-classic" className="each-shop-item" onMouseOver={() => setOpen('clean')} onMouseOut={() => setOpen(unhover)}>
            <img src={open === 'clean' ? Clean2 : Clean1} alt="shop Clean & Classic brand" />
            <h3>Clean & Classic</h3>
          </NavLink>
          <NavLink to="/shop/minimal-and-sleek" className="each-shop-item" onMouseOver={() => setOpen('minimal')} onMouseOut={() => setOpen(unhover)}>
            <img src={open === 'minimal' ? Minimal2 : Minimal1} alt="shop Minimal & Sleek brand" />
            <h3>Minimal & Sleek</h3>
          </NavLink>
          <NavLink to="/shop/classic-and-romantic" className="each-shop-item" onMouseOver={() => setOpen('romantic')} onMouseOut={() => setOpen(unhover)}>
            <img src={open === 'romantic' ? Romantic2 : Romantic1} alt="shop Classic & Romantic brand" />
            <h3>Classic & Romantic</h3>
          </NavLink>
          <NavLink to="/shop/classic-and-timeless" className="each-shop-item" onMouseOver={() => setOpen('timeless')} onMouseOut={() => setOpen(unhover)}>
            <img src={open === 'timeless' ? Timeless2 : Timeless1} alt="shop Classic & Timeless brand" />
            <h3>Classic & Timeless</h3>
          </NavLink>
        </div>
      </section>
      <ShopFooter image={FooterImage} />
    </main>
  );
};

export default Shop;