import '../../Style/Main.css';
import '../../Style/Shop-Page.css';
import 'react-slideshow-image/dist/styles.css'
import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../../Components/HeaderElements";
import Reco from "../../Components/Shop/Reco";

import Timeless1 from '../../Images/shop/classic-timeless/classic-timeless-1.png';
import Timeless2 from '../../Images/shop/classic-timeless/classic-timeless-2.png';
import Timeless3 from '../../Images/shop/classic-timeless/classic-timeless-3.png';
import Timeless4 from '../../Images/shop/classic-timeless/classic-timeless-4.png';
import Timeless5 from '../../Images/shop/classic-timeless/classic-timeless-5.png';
import Timeless6 from '../../Images/shop/classic-timeless/classic-timeless-6.png';
import Timeless7 from '../../Images/shop/classic-timeless/classic-timeless-7.png';
import Timeless8 from '../../Images/shop/classic-timeless/classic-timeless-8.png';
import Timeless9 from '../../Images/shop/classic-timeless/classic-timeless-9.png';
import Timeless10 from '../../Images/shop/classic-timeless/classic-timeless-10.png';
import Timeless11 from '../../Images/shop/classic-timeless/classic-timeless-11.png';
import Timeless12 from '../../Images/shop/classic-timeless/classic-timeless-12.png';

import CTspread from '../../Images/shop/classic-timeless/ct-spread.png';
import CTfooter from '../../Images/shop/classic-timeless/ct-footer.png';

import CT1 from '../../Images/shop/classic-timeless/ct-1.png';
import CT2 from '../../Images/shop/classic-timeless/ct-2.png';
import CT3 from '../../Images/shop/classic-timeless/ct-3.png';
import CT4 from '../../Images/shop/classic-timeless/ct-4.png';
import CT5 from '../../Images/shop/classic-timeless/ct-5.png';
import CT6 from '../../Images/shop/classic-timeless/ct-6.png';

import ShopPageIntro from "../../Components/Shop/ShopPageIntro"
import ShopFooter from "../../Components/Shop/ShopFooter"
import ImageSlider from "../../Components/Shop/ImageSlider"

// reco images
import Clean1 from '../../Images/shop/clean-classic/clean-classic-1.png';
import Clean2 from '../../Images/shop/clean-classic/clean-classic-2.png';
import Minimal1 from '../../Images/shop/minimal-sleek/minimal-sleek-1.png';
import Minimal2 from '../../Images/shop/minimal-sleek/minimal-sleek-2.png';
import Romantic1 from '../../Images/shop/classic-romantic/classic-romantic-6.png';
import Romantic2 from '../../Images/shop/classic-romantic/classic-romantic-2.png';

const images = [CT1, CT2, CT3, CT4, CT5, CT6];

const Timeless = () => {
  return (
    <main id='main' className='shop-page shop-single'>
      <Helmet>
        <title>Janel Lucia | Classic & Timeless Semi-Custom Brand</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="shop-atf">
        <div className="left">
          <ImageSlider images={images} />
        </div>
        <div className="right">
          <p>A Classic & Timeless Semi-Custom Brand:</p>
          <h1>Studio Selene</h1>
          <p>Introducing Studio Selene, our Classic & Timeless Semi-Custom Brand Kit, perfect for anyone seeking a touch of elegance and enduring style. This kit offers a refined and sophisticated aesthetic that enhances any business with its timeless charm. With customizable elements that reflect your unique identity, Studio Selene helps you create a brand presence that is both polished and memorable.  Elevate your brand with a look that effortlessly combines classic sophistication with modern simplicity.</p>
          <NavLink to="https://www.paypal.com/ncp/payment/ZN4JMWN6YRT28" target="new" activeStyle>Get this Brand customized for you</NavLink>
        </div>
      </section>
      <ShopPageIntro bgimage={CTspread} />

      <section className='wrapper shop-images'>
        <NavLink to="https://www.paypal.com/ncp/payment/ZN4JMWN6YRT28" target="new" className="floating-button" activeStyle>Buy Now</NavLink>
        <div className='section-text'>
          <h2>What is a Semi-Custom brand?</h2>
          <p>With 10 years of experience, I understand what it takes to create a brand that not only looks great but also connects and attracts. A semi-custom brand is ideal for entrepreneurs who want to invest in something that aligns with their business goals and values, without the need to spend over $5K or wait 6+ weeks to bring it to life.</p>
          <p>The semi-custom option transforms a pre-designed brand "template" by customizing it with your name, colours, fonts, and inspiration, along with my unique lettering. It offers a personalized experience with a faster turnaround and a more budget-friendly price. Rest assured, I ensure each brand stands out and looks entirely unique!</p>
        </div>
        <div className='section-text-2'><p>We'll change the name, secondary logo, tagline, colours, and the option to change the fonts! (includes one revision)</p></div>
        <img src={Timeless1} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Timeless2} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Timeless3} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Timeless4} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Timeless6} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Timeless5} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Timeless10} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Timeless8} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Timeless9} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Timeless7} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Timeless11} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Timeless12} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
      </section>
      <ShopFooter image={CTfooter} />
      <section className="client-call-to-action">
        <h2>Ready to Take Your <br className='mobile'></br><span className="h-italics">Brand to the Next Level?</span></h2>
        <p>Get this Brand customized for you.</p>
        <NavLink to="https://www.paypal.com/ncp/payment/ZN4JMWN6YRT28" target="new" className="call-to-action-btn" activeStyle>Buy Now</NavLink>
      </section>
      <Reco
        titleOne="Clean & Classic"
        linkOne="/shop/clean-and-classic"
        imageOne={Clean1}
        imageTwo={Clean2}
        titleTwo="Minimal & Sleek"
        linkTwo="/shop/minimal-and-sleek"
        imageThree={Minimal1}
        imageFour={Minimal2}
        titleThree="Classic & Romantic"
        linkThree="/shop/classic-and-romantic"
        imageFive={Romantic1}
        imageSix={Romantic2} />

    </main>
  );
};

export default Timeless;