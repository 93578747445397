const data = [
  {
    question: "What is actually customized & included?",
    answer: "We will replace the logo with your business name, your tagline, monogram initials  (or submark), custom-lettering (if included in the brand you chose), colours, and the option to change the primary font (if inline with the style of this brand, your palette, and your overall business aesthetic). We will also try to customize a unique pattern/texture for you and source 3-5 stock images! - You'll receive all brand elements in high res JPG, PNG and vector SVG and PDF. ",
  },
  {
    question: 'Will my brand kit files be editable?',
    answer: 'You can change the colours of your logos and icons using the SVGs in Canva. Your logo designs will be editable using the AI file in Adobe Illustrator, however all fonts will be converted to outlines.',
  },
  {
    question: 'How many revisions do I get?',
    answer: "You'll receive ONE revision, which often will be a tweak to a color or a small layout adjustment.If you need additional revisions, they are billed/ tracked at $100 / hr. P.S. — most customers only need one as they are just wanting a minor revision :) ",
  },
  {
    question: "How quickly will I receive my New Brand?",
    answer: "From your start day, it's about a week!!  Once you choose your day, submit your design questionnaire and provide your pinterest inspiration board, I'll personally reach out to you with any questions/comments. If your brand has custom lettering/calligraphy , it might add a day or two. But, MOST brands are turned around within a week :)",
  },
  {
    question: "What if don't know my colours & fonts?",
    answer: 'You can keep the colours of the brand kit to be the same ones as shown on this page above, or choose different colours that better match your vibe. Check out the Colour palette preview in each brand. Your colours will be provided in RGB, HEX and CMYK values inside the brand book you receive with the final files.',
  },
  {
    question: 'Are the fonts & lettering included?',
    answer: "They are not, but we do provide you with a link to purchase your own license as well as links to free font alternatives. We do suggest to purchase the font that the brand is designed around, just for cohesiveness. Most fonts are around $30 and a business write off :) — Regarding custom lettering: What you see in your brand is what is included (ie: a signature or tagline). If you need additional words / phrases / elements hand - written, they are billed separately. (p.s.we can also try to find you a font alternative).",
  },
  {
    question: 'Is this design exclusive? Will it be resold?',
    answer: 'Each brand kit will only be sold up to a maximum of 5 times to help you retain its uniqueness in the market. If you want exclusive ownership of your brand kit and are the first person to purchase it, you can choose to buyout the remaining seats for the kit at a discounted price, $1997. Or if you want a completely exclusive custom design, check out my services page.',
  },
  {
    question: "What if I don't like the font(s)?",
    answer: "The fonts in each brand template are specifically chosen to convey a certain mood that matches with the personality of the brand. If you don't like the fonts shown, choose a different brand template that you like the font of(and then we can swap the colors) or invest in a custom brand to get a logo tailored specifically to your design preferences.",
  }
];

export default data;