import React, { useState } from "react";
import { NavLink } from "../HeaderElements";
import '../../Style/Main.css';
import '../../Style/Home.css';
import '../../Style/Shop.css';

const Reco = (props) => {
  const [open, setOpen] = useState('');
  const unhover = () => {
    setOpen('')
  }
  return (
    <section className="wrapper">
      <div className="intro recommended-products">
        <h2 className="reco-title">Recommended for You</h2>
        <p>Find the perfect fit for your brand.</p>
        <div className="brand-kit-wrapper">
          <NavLink to={props.linkOne} className="each-shop-item" onMouseOver={() => setOpen('minimal')} onMouseOut={() => setOpen(unhover)}>
            <img src={open === 'minimal' ? props.imageTwo : props.imageOne} alt="shop Minimal & Sleek brand" />
            <h3>{props.titleOne}</h3>
          </NavLink>
          <NavLink to={props.linkTwo} className="each-shop-item" onMouseOver={() => setOpen('clean')} onMouseOut={() => setOpen(unhover)}>
            <img src={open === 'clean' ? props.imageFour : props.imageThree} alt="shop Clean & Classic brand" />
            <h3>{props.titleTwo}</h3>
          </NavLink>
          <NavLink to={props.linkThree} className="each-shop-item" onMouseOver={() => setOpen('romantic')} onMouseOut={() => setOpen(unhover)}>
            <img src={open === 'romantic' ? props.imageSix : props.imageFive} alt="shop Classic & Romantic brand" />
            <h3>{props.titleThree}</h3>
          </NavLink>
        </div>
        <div className="back-top-shop"><NavLink to="/shop">Back to shop</NavLink></div>
      </div >
    </section>
  );
};

export default Reco;