import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../Components/HeaderElements";
import Testimonials from "../Components/Testimonials.js";
import '../Style/Main.css';
import '../Style/Services.css';
import RecentWork from "../Components/RecentWork.js";


const Services = () => {
  return (
    <main id='main' className="wrapper service-page">
      <Helmet>
        <title>Janel Lucia | About</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="atf">
        <h1>Design, for the <br></br><span className="h-italics">Ambitious at Heart</span></h1>
        <p>Elevate your brand with custom design solutions tailored to your vision.</p>
      </section>

      <section className="service-wrap">
        <div className="service">
          <h2>Logo Design / Custom Logotype</h2>
          <p>We understand the power of a great logo. By creating a design that truly represents your brand, we help ensure it leaves a lasting impression. Whether you’re starting fresh or refining an existing logo, we’ll work with you to craft something that resonates deeply with your audience.</p>
        </div>
        <div className="service">
          <h2>Branding / Visual Identity</h2>
          <p>Your brand is meant to be seen and remembered. We’ll develop a cohesive visual identity that captures the essence of your brand, from logos and colour palettes to typography and visual style. Our goal is to create an identity that reflects who you are and connects with your audience.</p>
        </div>
        <div className="service">
          <h2>Website Design / Build</h2>
          <p>We believe your website should be as unique as your brand. Our approach to design and build ensures that your online presence stands out while providing a seamless user experience. Whether it’s on Squarespace, Shopify, or WordPress, we’re here to create a digital home that grows with your business.</p>
        </div>
        <div className="service">
          <h2>Editorial Design / Books / Magazines</h2>
          <p>Get a fresh dose of confidence in your printed products. This includes PDFs, Books / Magazines and Package Design, crafted with a custom mood board, colour palette, curated visual inspiration and creative direction.</p>
        </div>
        <div className="service">
          <h2>Packaging Design / Invites</h2>
          <p>We’re passionate about creating designs that make a difference. With a focus on collaboration, we’ll work closely with you to develop packaging and invites that not only look great but also capture the essence of your brand. Every detail, from texture to typography, is thoughtfully considered to ensure a memorable outcome.</p>
        </div>
        <div className="service">
          <h2>Social Media Content Creation</h2>
          <p>Your social media presence is crucial for connecting with your audience. We create strategically-driven content, including stunning graphics, engaging infographics, and attention-grabbing videos and animations, all tailored to reflect your brand's unique voice and visual identity.</p>
        </div>
      </section>

      <RecentWork />

      <section className="testimonials">
        <h2 className="h-italics">Kind Words</h2>
        <Testimonials />
      </section>

      <section className="client-call-to-action">
        <div className='wrapper'>
          <h2>Ready to Take Your <br className='mobile'></br><span className="h-italics">Brand to the Next Level?</span></h2>
          <p>Let’s create something beautiful together.</p>
          <NavLink to="/contact" className="each-project" activeStyle>Get Started</NavLink>
        </div>
      </section>

    </main>
  );
};

export default Services;


