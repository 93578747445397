import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export default class Testimonials extends Component {
  render() {
    return (
      <Carousel
        showArrows={true}
        showIndicators={false}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={8000}
      >
        <div>
          <div className="myCarousel">
            <p>Janel just gets it! I had reached a place that I needed help taking my brand to the next level. As soon as I had the first call with Janel, I knew I had to work with her! She walked me through a complete overhaul of my brand A-Z from the logo, brand identity and website with ease and a smile! Each and every call she brought solutions and design that aligned with my needs and vision. Since my branding and website have launched I’ve received only rave reviews about the look and feel of my website, all thanks to Janel!</p>
            <h3>— Sadie L'Orangerie Photographie —</h3>
          </div>
        </div>

        <div>
          <div className="myCarousel">
            <p>Loved working with Janel! She helped me rebuild my brand and website as well as helped me have a clear vision of what I wanted to achieve. She took the time to explain to me what works best together. Very professional and open minded, Janel was a pleasure to work with! </p>
            <h3>— Anne-Sophie Hout —</h3>
          </div>
        </div>

        <div>
          <div className="myCarousel">
            <p>I worked with Janel to create and re-launch my brand and website. Janel is incredible at what she does. She was able to capture my needs, who i was as a person and a professional, and she synthesized it impeccably into our creative process.  While working together, her ideas were so relevant for my industry and her level of talent and experience always came through. She was also so open to my ideas and what I felt mattered to my re-brand and she worked diligently at incorporating my feedback into the launch.</p>
            <h3>— Stephanie Boridy —</h3>
          </div>
        </div>

        <div>
          <div className="myCarousel">
            <p>Working with Janel was extremely beneficial for the branding and design aspect of my new business. Her creative process provided me with clarity and direction - ultimately helping me move forward with my vision. She took the time to understand me, my goals and aspirations for the business, and who I was serving and touching base frequently to ensure our objectives aligned.</p>
            <h3>— Faith Atria Coaching —</h3>
          </div>
        </div>

        <div>
          <div className="myCarousel">
            <p>Working with Janel has been amazing! I can’t recommend her enough. She was able to help problem-solve and trouble-shoot a lot of issues I was facing when developing my Services page. She is such a pleasure to work with. I definitely will be working with her again!</p>
            <h3>— Tati Moonsign Creative —</h3>
          </div>
        </div>

        <div>
          <div className="myCarousel">
            <p>What first drew me to Janel was her emphasis on human-centred design. She didn’t disappoint. Her first step was to work with me to build clarity on what I wanted. She took the time to understand the vision and mission of my business, my clients’ needs and our shared values. I trusted her completely when it came to design and branding and she surpassed my expectations!</p>
            <h3>— Jo Rodrigues Coaching —</h3>
          </div>
        </div>
      </Carousel>
    );
  }
}