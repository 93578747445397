import '../../Style/Main.css';
import '../../Style/Projects.css';
import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../../Components/HeaderElements";

import Letter1 from '../../Images/work/posters/lettering-1.png';
import Letter2 from '../../Images/work/posters/lettering-2.png';
import Letter3 from '../../Images/work/posters/lettering-3.png';
import Letter4 from '../../Images/work/posters/lettering-4.png';
import Letter5 from '../../Images/work/posters/lettering-5.png';
import Letter6 from '../../Images/work/posters/lettering-6.png';
import Letter7 from '../../Images/work/posters/lettering-7.png';
import Letter8 from '../../Images/work/posters/lettering-8.png';
import Letter9 from '../../Images/work/posters/lettering-9.png';
import Letter10 from '../../Images/work/posters/lettering-10.png';
import Letter11 from '../../Images/work/posters/lettering-11.png';
import Letter12 from '../../Images/work/posters/lettering-12.png';


import ProjectFooter from "../../Components/ProjectFooter"

const Lettering = () => {
  return (
    <main id='main' className='project-page wrapper'>
      <Helmet>
        <title>Janel Lucia | Having Fun</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="project-atf">
        <div className="project-title">
          <p>For the love of Typography & Inspiration</p>
          <h1>Lettering Archive</h1>
        </div>
        <div className="left">
          <p>Lettering Archive is a personal project dedicated to exploring the art of typography through a collection of inspiring quotes. This project serves as a creative outlet to explore my graphic design skills, emphasizing the beauty and versatility of type.</p>
          <p>Through Lettering Archive, I’ve experimented with various typographic styles and techniques, blending form and function to create visually engaging and thought-provoking designs. Each piece is crafted to capture the essence of the quote and convey its message through typographic treatments. This project highlights my passion for design and commitment to pushing the boundaries of traditional typography.</p>
          <p>Explore the collection to see how typography can transform words into visual art, and witness the evolution of my design practice through this curated archive.</p>
          <NavLink to="https://society6.com/letteringarchive/designs" target="_blank" activeStyle>Shop Posters</NavLink>
        </div>
      </section>

      <section className='three-across'>
        <img src={Letter1} alt="Poster visual" />
        <img src={Letter2} alt="Poster visual" />
        <img src={Letter3} alt="Poster visual" />
      </section>

      <section className='three-across'>
        <img src={Letter4} alt="Poster visual" />
        <img src={Letter5} alt="Poster visual" />
        <img src={Letter6} alt="Poster visual" />
      </section>

      <section className='three-across'>
        <img src={Letter7} alt="Poster visual" />
        <img src={Letter8} alt="Poster visual" />
        <img src={Letter9} alt="Poster visual" />
      </section>

      <section className='three-across'>
        <img src={Letter10} alt="Poster visual" />
        <img src={Letter11} alt="Poster visual" />
        <img src={Letter12} alt="Poster visual" />
      </section>

      <ProjectFooter />
    </main>
  );
};

export default Lettering;