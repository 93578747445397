import React, { useState } from "react";
import '../Style/Header.css';
import { Nav, NavLink, NavMenu } from "./HeaderElements";

import Hamburger from "./Hamburger";


function Header() {

  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen)
  }

  return (
    <>
      <header className='Header'>
        <Nav className='wrapper h-wrapper'>
          <NavMenu className='contact'>
            <NavLink to="/contact" className="desktop" activeStyle>Contact</NavLink>
          </NavMenu>
          <NavMenu className='logo'>
            <NavLink to="/" activeStyle>janel lucia // design studio</NavLink>
          </NavMenu>
          <NavMenu className='main-menu'>
            <div className='navigation' onClick={toggleHamburger}>
              <ul>
                <li><NavLink to="/recent-work">Work</NavLink></li>
                <li><NavLink to="/services">Services</NavLink></li>
                <li><NavLink to="/shop">Shop</NavLink></li>
                <li><NavLink to="/about">About</NavLink></li>
                <li><NavLink to="/contact">Contact</NavLink></li>
              </ul>
            </div>
            <div className="hamburger" onClick={toggleHamburger}>
              <Hamburger isOpen={hamburgerOpen} />
            </div>
          </NavMenu>
          <style jsx>{`
        .navigation ul {
          display: ${hamburgerOpen ? 'inline' : 'none'};
        }
        .hamburger .burger1 {
          transform: ${hamburgerOpen ? 'rotate(43deg)' : 'rotate(0deg)'};
          margin-top: ${hamburgerOpen ? '-6px' : '0'};
        }
                .hamburger .burger2 {
          opacity: ${hamburgerOpen ? '0' : '1'};
        }
        .hamburger .burger3 {
          transform: ${hamburgerOpen ? 'rotate(-43deg)' : 'rotate(0deg)'};
          margin-bottom: ${hamburgerOpen ? '-6px' : '0'};
        }
        `}</style>
        </Nav>
      </header>
    </>
  );
};

export default Header;