import '../Style/Main.css';
import '../Style/Contact.css';
import React from "react";
import { Helmet } from 'react-helmet';


const Contact = () => {
  return (
    <main id='main' className="wrapper">
      <Helmet>
        <title>Janel Lucia | About</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="atf contact-atf">
        <h1>Let's Create <br className='desktop'></br><span className="h-italics">Something Beautiful</span></h1>
        <p>As a heart-driven designer, it's important for me to work with clients who share the same values and integrity within their own business. If you believe we would be a great fit, please fill out the form below.</p>
      </section>

      <section className='contact-form'>

        <form action="https://api.staticforms.xyz/submit" method="post">
          <input type="hidden" name="accessKey" value="851a2b97-31f9-4d92-a8a1-18d4804fc519"></input>
          <div className='each-input'>
            <label for="Name">Name</label><br></br>
            <input type="text" name="$Name" placeholder="Name" required></input>
          </div>
          <div className='each-input'>
            <label for="Email">Email</label><br></br>
            <input type="email" name="$Email" placeholder='example@youremail.com' required></input>
          </div>
          <div className='each-input'>
            <label for="Business Name">Business Name</label><br></br>
            <input type="text" name="$Business Name" placeholder='Business Name'></input>
          </div>
          <div className='each-input'>
            <label for="Year in Business">Year in Business</label><br></br>
            <input type="text" name="$Year in Business" placeholder='Year in Business'></input>
          </div>
          <div className='each-input'>
            <label for="Website">Website</label><br></br>
            <input type="text" name="$Website" placeholder='Website'></input>
          </div>
          <div className='each-input'>
            <label for="Socials">Socials</label><br></br>
            <input type="text" name="$Socials" placeholder='Socials'></input>
          </div>
          <div className='each-textarea'>
            <label for="Design Needs">Design Needs</label><br></br>
            <textarea name="$Design Needs" placeholder='Branding, Website, Packaging, Templates, etc (be descriptive, the more the better!)' required></textarea>
          </div>
          <div className='each-textarea'>
            <label for="Experience">Experience</label><br></br>
            <textarea name="$Experience" placeholder="If you've worked with a designer in the past, what areas do you wish improved throughout that experience?" required></textarea>
          </div>
          <div className='each-textarea'>
            <label for="Tell us About You">Tell us About You</label><br></br>
            <textarea name="$Tell us About You" placeholder='What you do, who you are, your uniqueness, your design goals, etc' required></textarea>
          </div>
          <div className='each-input each-textarea'>
            <label for="Ideals Launch Date">Ideal Launch Date</label><br></br>
            <input type="date" name="$Ideal Launch Date"></input>
          </div>

          <input type="hidden" name="replyTo" value="@"></input>
          <input type="hidden" name="redirectTo" value="https://janellucia.com/thank-you"></input>

          <input type="submit" value="Submit" className='submit' />
        </form>
      </section>

      <section className='contact-footer'>
        <h3>Thank you for considering working with me.</h3> <p>I look forward to the opportunity to bring your vision to life.</p>
      </section>

    </main >
  );
};

export default Contact;