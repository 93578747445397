import React from 'react';
import { Fade } from 'react-slideshow-image';



const ImageSlider = ({ images }) => {
  return (
    <div className="slide-container">
      <Fade
        arrows={false}
        duration={1500}
        onChange={function noRefCheck() { }}
        onStartChange={function noRefCheck() { }}
      >
        {images.map((image, index) =>
          <div key={index} className="each-fade">
            <div className="image-container">
              <img src={image} alt="" />
            </div>
          </div>
        )}
      </Fade>
    </div>
  );
}

export default ImageSlider;