import '../../Style/Main.css';
import '../../Style/Projects.css';
import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../../Components/HeaderElements";

import Livie1 from '../../Images/work/livie/livie-1.png';
import Livie2 from '../../Images/work/livie/livie-2.png';
import Livie3 from '../../Images/work/livie/livie-3.png';
import Livie4 from '../../Images/work/livie/livie-4.png';
import Livie5 from '../../Images/work/livie/livie-5.png';
import Livie6 from '../../Images/work/livie/livie-6.png';
import Livie7 from '../../Images/work/livie/livie-7.png';
import Livie8 from '../../Images/work/livie/livie-8.png';
import Livie9 from '../../Images/work/livie/livie-9.png';
import Livie10 from '../../Images/work/livie/livie-10.png';


import ProjectFooter from "../../Components/ProjectFooter"

const Livie = () => {
  return (
    <main id='main' className='project-page wrapper'>
      <Helmet>
        <title>Janel Lucia | Livie Jewelry branding & website</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="project-atf">
        <div className="project-title">
          <p>Elegance and Edge in Every Piece</p>
          <h1>Livie Jewelry</h1>
        </div>
        <div className="left">
          <p>Livie Jewelry, designed by Jules Beliavski, is an exclusive Toronto-based brand known for its distinctive balance of edgy and feminine elements. The collection is crafted to seamlessly transition from day to night, offering versatile pieces that enhance any look with sophistication and style.</p>
          <p>We teamed up with Jules and FE developer Chiara to develop a compelling brand identity for Livie Jewelry. This project included designing a bold logo with strong typography and creating a clean, intuitive website on Shopify. The goal was to reflect the brand’s unique aesthetic while providing an exceptional online shopping experience. The result is a visually striking site that beautifully showcases the jewelry collection and enhances the brand’s elegant and edgy character.</p>
          <NavLink to="https://www.liviejewelry.com/" target="_blank" activeStyle>Livie Jewelry Website</NavLink>
        </div>
        <div className="right">
          <h2>Services</h2>
          <ul>
            <li>Brand Identity</li>
            <li>Logo Design</li>
            <li>Shopify Design</li>
            <li>Design Accessibility</li>
            <li>Web Accessibility</li>
          </ul>
        </div>
      </section>

      <section className='three-across'>
        <img src={Livie1} alt="Livie Jewelry website and Branding visual" />
        <img src={Livie2} alt="Livie Jewelry website and Branding visual" />
        <img src={Livie3} alt="Livie Jewelry website and Branding visual" />
      </section>

      <section className='two-across'>
        <img src={Livie4} alt="Livie Jewelry website and Branding visual" />
        <img src={Livie5} alt="Livie Jewelry website and Branding visual" />
      </section>

      <section className='three-across'>
        <img src={Livie6} alt="Livie Jewelry website and Branding visual" />
        <img src={Livie7} alt="Livie Jewelry website and Branding visual" />
        <img src={Livie8} alt="Livie Jewelry website and Branding visual" />
      </section>

      <section className='two-across two-across-alt'>
        <img src={Livie9} alt="Livie Jewelry website and Branding visual" />
        <img src={Livie10} alt="Livie Jewelry website and Branding visual" />
      </section>

      <ProjectFooter />
    </main>
  );
};

export default Livie;