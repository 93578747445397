import '../../Style/Main.css';
import '../../Style/Projects.css';
import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../../Components/HeaderElements";

import Scrungee1 from '../../Images/work/the-scrungee/the-scrungee-1.png';
import Scrungee2 from '../../Images/work/the-scrungee/the-scrungee-2.png';
import Scrungee3 from '../../Images/work/the-scrungee/the-scrungee-3.png';
import Scrungee4 from '../../Images/work/the-scrungee/the-scrungee-4.png';
import Scrungee5 from '../../Images/work/the-scrungee/the-scrungee-5.png';
import Scrungee6 from '../../Images/work/the-scrungee/the-scrungee-6.png';
import Scrungee7 from '../../Images/work/the-scrungee/the-scrungee-7.png';
import Scrungee8 from '../../Images/work/the-scrungee/the-scrungee-8.png';
import Scrungee9 from '../../Images/work/the-scrungee/the-scrungee-9.png';
import Scrungee10 from '../../Images/work/the-scrungee/the-scrungee-10.png';
import Scrungee11 from '../../Images/work/the-scrungee/the-scrungee-11.png';

import ProjectFooter from "../../Components/ProjectFooter"

const Scrungee = () => {
  return (
    <main id='main' className='project-page wrapper'>
      <Helmet>
        <title>Janel Lucia | The Scrungee branding & package design</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="project-atf">
        <div className="project-title">
          <p>Elevating Everyday Hair Styling</p>
          <h1>The Scrungee</h1>
        </div>
        <div className="left">
          <p>The Scrungee™, co-created by celebrity hairstylist Matthew Collins and luxury hair accessory designer Chelsea King, is revolutionizing everyday hair styling with professional-grade tools. Featuring a sleek linear design and proprietary elastic, The Scrungee™ offers a unique solution for holding any hairstyle in place without the need to loop hair through traditional elastics.</p>
          <p>We partnered with The Scrungee team to develop a brand identity that embodies their innovative approach. This included designing a distinctive logo with strong typography and creating packaging that reflects the product’s modern and functional design. The result is a brand presence that enhances The Scrungee™'s appeal and communicates its premium quality effectively.</p>
          <NavLink to="https://chelseaking.shop/collections/the-scrungee" target="_blank" activeStyle>Shop Now</NavLink>
        </div>
        <div className="right">
          <h2>Services</h2>
          <ul>
            <li>Brand Identity</li>
            <li>Logo Design</li>
            <li>Seamless Pattern</li>
            <li>Illustrated Icon</li>
            <li>Package Design</li>
          </ul>
        </div>
      </section>

      <section className='three-across'>
        <img src={Scrungee1} alt="The Scrungee Branding visual" />
        <img src={Scrungee2} alt="The Scrungee Branding visual" />
        <img src={Scrungee3} alt="The Scrungee Branding visual" />
      </section>

      <section className='two-across'>
        <img src={Scrungee4} alt="The Scrungee Branding visual" />
        <img src={Scrungee5} alt="The Scrungee Branding visual" />
      </section>

      <section className='three-across'>
        <img src={Scrungee8} alt="The Scrungee Branding visual" />
        <img src={Scrungee7} alt="The Scrungee Branding visual" />
        <img src={Scrungee6} alt="The Scrungee Branding visual" />
      </section>

      <section className='one-across'>
        <img src={Scrungee9} alt="The Scrungee Branding visual" />
      </section>

      <section className='two-across'>
        <img src={Scrungee11} alt="The Scrungee Branding visual" />
        <img src={Scrungee10} alt="The Scrungee Branding visual" />
      </section>

      <ProjectFooter />
    </main>
  );
};

export default Scrungee;