import React from "react";
import '../../Style/Main.css';
import '../../Style/Shop.css';
import '../../Style/Shop-Page.css';



function ShopPageIntro(props) {
  return (
    <>
      <div>
        <section className='shop-item-intro'>
          <div className="wrapper">
            <h2>How do you <span className="h-italics">really</span> feel about your brand?</h2>
            <p className="sub-title">Is it just okay, or could it be amazing?</p>
            <p>Picture this: someone compliments your brand, and you feel that instant surge of pride, a rush of excitement. You’ve poured your heart into your business, and now, your brand reflects that dedication. It’s authentic, valuable, and unmistakably you.</p>
          </div>
        </section>
        <section className="paralax" style={{ backgroundImage: `url(${props.bgimage})` }} >
          <div className="wrapper">
            <h2>Ready to experience a <span className="h-italics">confidence boost?</span></h2>
            <p>If you are struggling to pair fonts, create a color palette, or find it difficult to use brand elements consistently throughout your marketing, let me help you ;) These semi-custom brands are not only stunning but will help you create a seamless brand experience that will attract your ideal client or customer.</p>
          </div>
        </section>
      </div >
    </>
  );
};

export default ShopPageIntro;