import '../../Style/Main.css';
import '../../Style/Projects.css';
import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../../Components/HeaderElements";

import BrieHagarty1 from '../../Images/work/brie-hagarty/brie-hagarty-1.png';
import BrieHagarty2 from '../../Images/work/brie-hagarty/brie-hagarty-2.png';
import BrieHagarty3 from '../../Images/work/brie-hagarty/brie-hagarty-3.png';
import BrieHagarty4 from '../../Images/work/brie-hagarty/brie-hagarty-4.png';
import BrieHagarty5 from '../../Images/work/brie-hagarty/brie-hagarty-5.png';
import BrieHagarty6 from '../../Images/work/brie-hagarty/brie-hagarty-6.png';
import BrieHagarty7 from '../../Images/work/brie-hagarty/brie-hagarty-7.png';
import BrieHagarty8 from '../../Images/work/brie-hagarty/brie-hagarty-8.png';
import BrieHagarty9 from '../../Images/work/brie-hagarty/brie-hagarty-9.png';
import BrieHagarty10 from '../../Images/work/brie-hagarty/brie-hagarty-10.png';
import BrieHagarty11 from '../../Images/work/brie-hagarty/brie-hagarty-11.png';

import ProjectFooter from "../../Components/ProjectFooter"

const Brie = () => {
  return (
    <main id='main' className='project-page wrapper'>
      <Helmet>
        <title>Janel Lucia | High Vibe Hair Artist branding & website</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="project-atf">
        <div className="project-title">
          <p>A Harmonious Blend of Art and Design</p>
          <h1>High Vibe Hair Artist</h1>
        </div>
        <div className="left">
          <p>Brie Hagarty (aka High vibe Hair Artist) is a hairstylist with over 10 years of experience, bringing beautiful hair to her clients. She is also a natural intuitive, energy healer, spiritual teacher, and self-mastery mentor. Brie works to combine these services, creating a space where her clients can relax, let go, and express their personal style. Her holistic approach not only transforms appearances but also uplifts the spirit, offering a truly unique and personal experience.</p>
          <p>Our collaboration with Brie focused on designing a brand identity that reflects her multifaceted talents. By blending calming, earth-toned colors with elegant typography and fluid design elements, we created a visual narrative that speaks to both her artistry and her spiritual work. This project captures the essence of Brie's mission—empowering clients to feel beautiful inside and out. Explore how we brought her vision to life through thoughtful and intentional design.</p>
          <NavLink to="https://www.briehagarty.com/" target="_blank" activeStyle>Brie Hagarty website</NavLink>
        </div>
        <div className="right">
          <h2>Services</h2>
          <ul>
            <li>Brand Identity</li>
            <li>Logo Design</li>
            <li>Squarespace site</li>
            <li>Custom Website</li>
            <li>Web Accessibility</li>
          </ul>
        </div>
      </section>

      <section className='three-across'>
        <img src={BrieHagarty1} alt="Brie Hagarty High Vibe Hair Artist Branding visual" />
        <img src={BrieHagarty2} alt="Brie Hagarty High Vibe Hair Artist Branding visual" />
        <img src={BrieHagarty3} alt="Brie Hagarty High Vibe Hair Artist Branding visual" />
      </section>

      <section className='two-across'>
        <img src={BrieHagarty4} alt="Brie Hagarty High Vibe Hair Artist Branding visual" />
        <img src={BrieHagarty5} alt="Brie Hagarty High Vibe Hair Artist Branding visual" />
      </section>

      <section className='three-across'>
        <img src={BrieHagarty6} alt="Brie Hagarty High Vibe Hair Artist Branding visual" />
        <img src={BrieHagarty7} alt="Brie Hagarty High Vibe Hair Artist Branding visual" />
        <img src={BrieHagarty8} alt="Brie Hagarty High Vibe Hair Artist Branding visual" />
      </section>

      <section className='three-across'>
        <img src={BrieHagarty9} alt="Brie Hagarty High Vibe Hair Artist Branding visual" />
        <img src={BrieHagarty10} alt="Brie Hagarty High Vibe Hair Artist Branding visual" />
        <img src={BrieHagarty11} alt="Brie Hagarty High Vibe Hair Artist Branding visual" />
      </section>

      <ProjectFooter />
    </main>
  );
};

export default Brie;